import { useLazyQuery } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { RewardCard } from 'components/Cards/RewardCard/RewardCard';
import { HistoryList } from './HistoryList/HistoryList';
import { Layout } from 'components/Layout/Layout';
import { USER_REWARDS } from 'graphql/queries/userRewards';
import { USER_REWARDS_SUM } from 'graphql/queries/userRewardsSum';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CashbackHeading } from './CashbackHeading/CashbackHeading';
import { appContext } from 'views/App';
import { USER_REWARDS_PREVIOUS } from 'graphql/queries/userRewardsPrevious';
import { groupCashbackHistoryByMonth } from 'utils/groupCashbackHistoryByMonth';
import { CashbackItem, CashbackPrevHistoryItem } from 'types/Cashback';
import { PrevHistoryList } from './PrevHistoryList/PrevHistoryList';

const StyledUserBar = styled.div<{ isUserAppSettingsColor?: boolean }>`
  padding-top: 20px;
  .bankTransferButton {
    position: absolute;
    top: 0;
    max-width: 150px;
    right: 26px;
    color: ${({ isUserAppSettingsColor }) =>
      isUserAppSettingsColor ? '#fff' : 'var(--green)'};
    background: ${({ isUserAppSettingsColor }) =>
      isUserAppSettingsColor
        ? 'rgba(255, 255, 255, 0.2)'
        : 'rgba(1, 213, 75, 0.2)'};
  }
`;

export type CashbackStatus = 'pending' | 'claimed';

export const Cashback = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  const setIsLoading = appContextStore?.setIsLoading;
  const setIsError = appContextStore?.setIsError;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cashbackHistory, setCashbackHistory] = useState<CashbackItem[] | null>(
    null,
  );
  const [cashbackPreviousHistory, setCashbackPreviousHistory] =
    useState<CashbackPrevHistoryItem[]>(null);
  const [totalRewards, setTotalRewards] = useState<number | null>(null);
  const [selectedCashbackStatus, setSelectedCashbackStatus] =
    useState<CashbackStatus>('pending');

  const [userRewards, { loading: userRewardsHistoryLoading }] = useLazyQuery(
    USER_REWARDS,
    {
      onCompleted: (data) => {
        if (!data) return;
        setCashbackHistory(data.userRewards);
      },
      onError: (error) => {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    },
  );
  const [userRewardsSum, { loading: userRewardsSumLoading }] = useLazyQuery(
    USER_REWARDS_SUM,
    {
      onCompleted: (data) => {
        if (!data) return;
        setTotalRewards(data.userRewardsSum);
      },
      onError: (error) => {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    },
  );
  const [userRewardsPrevious, { loading: userRewardsPreviousLoading }] =
    useLazyQuery(USER_REWARDS_PREVIOUS, {
      onCompleted: (data) => {
        if (!data) return;
        const newHistory = groupCashbackHistoryByMonth(
          data.userRewardsPrevious,
        );
        setCashbackPreviousHistory(newHistory);
      },
      onError: (error) => {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    });

  useEffect(() => {
    if (!userData) return;
    userRewards();
    userRewardsSum();
    userRewardsPrevious();
  }, [userData]);

  useEffect(() => {
    setIsLoading(
      userRewardsHistoryLoading ||
        userRewardsSumLoading ||
        userRewardsPreviousLoading,
    );
  }, [
    userRewardsHistoryLoading,
    userRewardsSumLoading,
    userRewardsPreviousLoading,
  ]);

  return (
    <Layout
      isBackBtn
      backButtonMode="text"
      viewTitle={t('cashback')}
      mode="medium"
      backBtnLink="/profile"
      userBarContent={
        <StyledUserBar
          isUserAppSettingsColor={Boolean(userData?.appSettings?.primary_color)}
        >
          <RewardCard
            totalReward={totalRewards}
            isTotalRewards={selectedCashbackStatus === 'pending'}
          />
          <Button
            mode="alt"
            fontSize="s"
            className="bankTransferButton"
            onBtnClick={() => navigate('/bank-transfer')}
          >
            {t('bankTransfer')}
          </Button>
        </StyledUserBar>
      }
    >
      <CashbackHeading
        handleSelectedStatusChange={(status) =>
          setSelectedCashbackStatus(status)
        }
        selectedCashbackStatus={selectedCashbackStatus}
      />
      {selectedCashbackStatus === 'pending' ? (
        <HistoryList
          historyList={cashbackHistory?.map((item) => {
            return {
              title: `Report ${
                item.report?.id ? item.report?.id : item.gasStationReport?.id
              } `,
              date: item.createdAt,
              amount: item.amount,
            };
          })}
        />
      ) : (
        <PrevHistoryList history={cashbackPreviousHistory} />
      )}
    </Layout>
  );
};
