import { Layout } from 'components/Layout/Layout';
import { Button, ButtonProps } from 'components/Button/Button';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { UserInfo } from 'components/UserInfo/UserInfo';
import { passwordValidation } from 'data/regexPatterns';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useMutation } from '@apollo/client';
import { UPDATE_PASSWORD } from 'graphql/mutations/updatePassword';
import { Popup } from 'components/Popup/Popup';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { appContext } from 'views/App';

const StyledButton = ({ className, children, onBtnClick }: ButtonProps) => (
  <Button mode="alt" className={className} onBtnClick={onBtnClick}>
    {children}
  </Button>
);

const StyledForm = styled.form`
  padding-bottom: 15px;
`;
const OverstyledButton = styled(StyledButton)<{
  isUserAppSettingsColor: boolean;
}>`
  width: max-content;
  margin-left: auto;
  color: ${({ isUserAppSettingsColor }) =>
    isUserAppSettingsColor ? '#fff' : 'var(--green)'};
  background: ${({ isUserAppSettingsColor }) =>
    isUserAppSettingsColor
      ? 'rgba(255, 255, 255, 0.2)'
      : 'rgba(1, 213, 75, 0.2)'};
`;

const StyledBottomContainer = styled.div`
  height: 46px;
`;

export const ChangePassword = () => {
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;
  const userData = appContextStore?.userData;

  const { t } = useTranslation();
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const methods = useForm({
    mode: 'onSubmit',
  });
  const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD, {
    onCompleted: () => {
      setIsPopupOpened(true);
    },
    onError: (error) => {
      if (error.message === 'Validation Exception') {
        setError('currentPasswordValidationError');
        setIsLoading(false);
      } else {
        setIsError(true);
        setIsLoading(false);
      }
      throw new Error(error.message);
    },
  });

  const {
    formState: { isDirty },
    getValues,
    handleSubmit,
    watch,
  } = methods;

  const onSubmit = () => {
    if (getValues('passwordConfirmation') !== getValues('newPassword')) {
      setError('confirmNewPasswordValidationError');
    } else {
      if (isDirty) {
        handlePasswordChange();
      }
    }
  };

  const handlePasswordChange = () => {
    const oldPassword = getValues('oldPassword');
    const password = getValues('newPassword');
    const passwordConfirmation = getValues('passwordConfirmation');
    updatePassword({
      variables: { oldPassword, password, passwordConfirmation },
    });
  };

  useEffect(() => {
    setError(undefined);
  }, [
    watch('oldPassword'),
    watch('newPassword'),
    watch('passwordConfirmation'),
  ]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  const userBar = (
    <div>
      <OverstyledButton
        onBtnClick={handleSubmit(onSubmit)}
        isUserAppSettingsColor={Boolean(userData?.appSettings?.primary_color)}
      >
        {t('done')}
      </OverstyledButton>
      <UserInfo layout={'vertical'} align="center" size="m" />
    </div>
  );
  return (
    <Layout
      isMenuBar
      userBarContent={userBar}
      isBackBtn
      backButtonMode="text"
      viewTitle={t('changePassword')}
      outsideElements={
        <Popup
          isPopupOpened={isPopupOpened}
          onDecline={() => navigate('/profile')}
          content={t('confirmChangeSave')}
          confirmText="ok"
          type="info"
        />
      }
    >
      <FormProvider {...methods}>
        <StyledForm>
          <PasswordInput
            name="oldPassword"
            label={t('oldPassword')}
            isRequired
            requiredErrorMessage={t('requiredError')}
            validation={passwordValidation}
            validationErrorMessage={t('passwordValidationError')}
            mode="light"
            placeholder={t('oldPasswordPlaceholder')}
            bottomMarting="16"
          />
          <PasswordInput
            name="newPassword"
            label={t('newPassword')}
            isRequired
            requiredErrorMessage={t('requiredError')}
            validation={passwordValidation}
            validationErrorMessage={t('passwordValidationError')}
            mode="light"
            placeholder={t('newPasswordPlaceholder')}
            bottomMarting="16"
          />
          <PasswordInput
            name="passwordConfirmation"
            label={t('confirmPassword')}
            isRequired
            requiredErrorMessage={t('requiredError')}
            validation={passwordValidation}
            validationErrorMessage={t('passwordValidationError')}
            mode="light"
            placeholder={t('confirmPasswordPlaceholder')}
            bottomMarting="16"
          />
        </StyledForm>
      </FormProvider>
      {error && <Paragraph color="var(--error)">{t(error)}</Paragraph>}
      <StyledBottomContainer />
    </Layout>
  );
};
